<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增单据弹框
	开始时间：2021-08-04
	开发人员：康泽鑫
	最后修改：2021-08-05
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="expense_create">
    <el-dialog :title="form.id?(operateType==1?'修改单据':'预览单据'):'新增单据'" :visible.sync="dialogFormVisible" width="800px"
      append-to-body :before-close="close" :destroy-on-close="true">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" size="small">
        <el-row>
          <!-- 单据编号 -->
          <el-col :span="12">
            <el-form-item label="单据编号:" prop="billsNo">
              <el-input class="ze_input" v-model="form.billsNo" disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <!-- 单据日期 -->
          <el-col :span="12">
            <el-form-item label="单据日期:" prop="billsDate">
              <el-date-picker class="ze_input" v-model="form.billsDate" type="date" placeholder="选择日期"
                :disabled="disabled">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 费用分类 -->
          <el-col :span="12" v-if="isShowCostType">
            <el-form-item label="费用分类:" prop="costType">
              <el-input class="ze_input" v-model="form.costType" placeholder="请选择费用分类" autocomplete="off"
                suffix-icon="el-icon-more" @focus="judgeRelatedData(3)" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <!-- 收付款账号 -->
          <el-col :span="12">
            <el-form-item :label="labelOrderBank+':'" prop="orderBankId">
              <el-select class="ze_input" v-model="form.orderBankId" :placeholder="placeholderOrderBank"
                :disabled="disabled">
                <el-option v-for="item in SysCostBankInfo" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 金额 -->
          <el-col :span="12" v-if="isShowTotalAmount">
            <el-form-item label="金额:" prop="totalAmount">
              <el-input class="ze_input" v-model="form.totalAmount" placeholder="请输入金额" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <!-- 部门 -->
          <el-col :span="12">
            <el-form-item label="部门:" prop="dept">
              <el-input class="ze_input" v-model="form.dept" placeholder="请选择部门" autocomplete="off"
                suffix-icon="el-icon-more" @focus="judgeRelatedData(0)" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <!-- 员工 -->
          <el-col :span="12">
            <el-form-item label="员工:" prop="userName">
              <el-input class="ze_input" v-model="form.userName" placeholder="请选择员工" autocomplete="off"
                suffix-icon="el-icon-more" @focus="judgeRelatedData(1)" :disabled="disabled">
              </el-input>
            </el-form-item>
          </el-col>
          <!-- 客户供应商 -->
          <el-col :span="12">
            <el-form-item :label="labelCustomer+':'" prop="customerName">
              <el-input class="ze_input" v-model="form.customerName" :placeholder="placeholderCustomer"
                autocomplete="off" :disabled="disabled">
                <i slot="suffix" class="el-icon-more icon" @click="judgeRelatedData(2)"></i>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- 事由 -->
          <el-col :span="12">
            <el-form-item label="事由:" prop="reason">
              <el-input class="ze_input" v-model="form.reason" placeholder="请输入事由" autocomplete="off"
                :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <!-- 经办人 -->
        <el-col :span="12">
          <el-form-item label="经办人:">
            <el-input class="ze_input" v-model="UserInfo.user_name" disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <!-- 审批人 -->
        <el-col :span="12">
          <el-form-item label="审批人:">
            <el-input class="ze_input" v-model="form.chargeUser" disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="makesure">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 部门选择弹框 -->
    <departmentTree @SelectedData="getDepartmentData"></departmentTree>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData"></personnelList>

    <!-- 客户选择弹框 -->
    <customerList @SelectedData="getCustomerData"></customerList>

    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData"></costTypeTree>

    <!-- 供应商选择弹框 -->
    <supplierList @SelectedData="getSupplierData"></supplierList>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  import sellApi from '@/network/sell/bills.js'; //销售相关接口
  import costApi from '@/network/cost/account.js'; //资金相关接口
  import workApi from '@/network/system/workflowSetup.js'; //工作流程相关接口请求

  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue'; //费用分类组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件
  export default {
    name: 'warehouse_create',
    props: {
      //弹窗开关
      dialogFormVisible: Boolean,
      //节点分类标志(0:销售 1:生产 2:库存  3:采购  4:bom  5.收付款  6:资金)
      billsTypeSign: {
        type: Number,
        default: -1
      },
      //单据类型(4:销售支出单 5:日常收入单 6:日常支出单)
      expenseType: Number,
      //修改的单据数据
      editForm: Object,
      //操作类型(1:修改 2:预览)
      operateType: Number
    },
    data() {
      return {
        //单据代码
        billsCode: '',

        //节点信息
        NodeBaseInfo: [],

        //单据信息
        billData: [],

        /* 表单相关 */
        isShowCostType: false, //是否显示费用分类
        labelOrderBank: '付款账号', //收付款账号label
        placeholderOrderBank: '请选择付款账号', //收付款账号文字提示
        isShowTotalAmount: false, //是否显示金额
        labelCustomer: '关联客户', //客户label
        placeholderCustomer: '请输入关联客户', //客户文字提示
        disabled: false, //禁用编辑

        //表单信息
        form: {
          billsNo: '', //单据编号
          billsDate: "", //单据日期
          orderBank: "", //付款账号
          orderBankId: "", //付款账号id
          totalAmount: "", //金额
          dept: "", //部门
          deptId: "", //部门id
          userName: "", //员工
          userId: "", //员工id
          customerName: "", //客户
          customerId: "", //客户id
          reason: "", //事由
          costType: "", //费用分类
          costTypeId: "", //费用分类id
          createUserId: "", //制单人
          chargeUser: "", //审核人
          chargeUserids: "", //审核人id
          costClassify: "", //分类
          billingTypeId: "", //单据类型id
          statusId: "", //节点id
          processId: "", //流程id
        },

        //源数据
        sourceData: {},

        //表单校验
        rules: {
          orderBankId: [{
            required: true,
            message: '请选择付款账号',
            trigger: 'change'
          }],
          dept: [{
            required: true,
            message: '请至少选择一个部门',
            trigger: 'change'
          }],
          userName: [{
            required: true,
            message: '请至少选择一个员工',
            trigger: 'change'
          }],
          customerName: [{
            required: true,
            message: '请选择关联客户',
            trigger: 'blur'
          }],
          reason: [{
            required: true,
            message: '请输入事由',
            trigger: 'blur'
          }],
          costType: [{
            required: true,
            message: '请输入费用分类',
            trigger: 'change'
          }],
          totalAmount: [{
            required: true,
            message: '请输入金额',
            trigger: 'blur'
          }],
        }
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        SysCostBankInfo: state => state.system.SysCostBankInfo, //收款账号数据
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
      })
    },
    created() {
      this.initVuexData() //初始化vuex
      this.form.billsDate = this.commonJsExtend.getDateTime(new Date(), 0); // 获取当前日期
    },
    methods: {
      ...mapMutations([
        'SHOW_CUSTOMERBOX', //控制客户选择弹框是否显示
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
      ]),
      ...mapActions([
        'getSysCostBankInfo', //收款账号
        'getelectronicTemplate', //获取所有单据类型
      ]),

      /* 初始化Vuex */
      async initVuexData() {
        //单据代码(日常收入单:"DOC-C-006" 日常支出单:"DOC-C-007" 销售支出单:"DOC-C-008")
        switch (this.expenseType) {
          case 4:
            this.billsCode = 'DOC-C-008'; //销售支出单
            this.form.costClassify = 4; //分类
            break;
          case 5:
            this.billsCode = 'DOC-C-006'; //日常收入单
            this.form.costClassify = 5; //分类
            this.isShowCostType = true; //是否显示费用分类
            this.labelOrderBank = '收款账号'; //收付款账号label
            this.placeholderOrderBank = '请选择付款账号'; //收付款账号文字提示
            this.isShowTotalAmount = true; //是否显示金额
            this.labelCustomer = '付款人'; //客户label
            this.placeholderCustomer = '请输入客户'; //客户文字提示
            break;
          case 6:
            this.billsCode = 'DOC-C-007'; //日常支出单
            this.form.costClassify = 6; //分类
            this.isShowCostType = true; //是否显示费用分类
            this.isShowTotalAmount = true; //是否显示金额
            this.labelCustomer = '付款人'; //客户label
            this.placeholderCustomer = '请输入供应商'; //客户文字提示
            break;
        }
        //判断收款账号是否存在
        if (this.SysCostBankInfo.length == 0) { //不存在则请求数据
          this.getSysCostBankInfo();
        }
        if (JSON.stringify(this.editForm) != "{}") { //修改时
          this.form = this.editForm
          this.sourceData = JSON.parse(JSON.stringify(this.editForm))
          if (this.operateType == 2) {
            this.disabled = true //禁用编辑
          }
          return
        }
        //判断单据类型数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        //获取指定单据类型数据
        this.billTemplateData.forEach(itemI => {
          itemI.children.forEach(itemJ => {
            if (itemJ.code == this.billsCode) {
              this.billData = itemJ
              this.form.billingTypeId = itemJ.id //获取单据类型id
              this.getNodeInfo() //获取节点信息
              this.getbillsNo(this.billData) //获取单据编号
            }
          })
        })
      },

      /* 获取节点信息 */
      getNodeInfo() {
        let nodeNextData = {
          id: 80
        }
        //发送请求
        workApi.getWorkflowSetupNodeById(nodeNextData).then(res => {
          if (res.code == 200) {
            this.NodeBaseInfo = res.data.node; //获取节点基本信息
            this.form.chargeUser = this.NodeBaseInfo.chargeUser; //审核人
            this.form.chargeUserids = this.NodeBaseInfo.charge_userids //审核人id
            this.form.statusId = 80 //节点id
            this.form.processId = this.NodeBaseInfo.id //流程id
          } else {
            this.$message({
              type: 'error',
              message: '节点信息获取失败,请确认...',
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取单据编号 */
      getbillsNo(data) {
        //设置传入后端的参数
        let numData = {
          templateId: data.id, //单据类型id
          sign: this.billsTypeSign, //类型分类id
        }
        // 发送请求
        sellApi.findCodeByFormatStr(numData).then(res => {
          if (res.code == 200) {
            this.form.billsNo = res.data;
          }
        })
      },

      /* 获取所选择的部门数据 */
      getDepartmentData(data) {
        let result = this.commonJsExtend.getDeptMsg(data);
        this.form.dept = result.nameStr; //部门
        this.form.deptId = result.idStr; //部门id
        this.$forceUpdate()
      },

      /* 获取所选择的员工数据 */
      getPersonnelData(data) {
        let result = this.commonJsExtend.getPersonnelMsg(data);
        this.form.userName = result.nameStr; //员工
        this.form.userId = result.idStr; //员工id
        this.$forceUpdate()
      },

      /* 获取所选择的客户数据 */
      getCustomerData(data) {
        this.form.customerName = data.customer_name; //客户
        this.form.customerId = data.customer_id; //客户id
        this.$forceUpdate()
      },

      /* 获取所选择的费用分类 */
      getCostTypeData(data) {
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个费用',
            duration: this.elDuration
          })
          return
        }
        this.form.costType = data[0].name; //费用分类
        this.form.costTypeId = data[0].id; //费用分类id
      },

      /* 获取所选择的供应商 */
      getSupplierData(data) {
        this.form.customerName = data.fullName; //供应商
        this.form.customerId = data.id; //供应商id
      },

      /* 控制弹窗显示隐藏 type=> 0:部门弹框 1:部门员工弹框 2:客户选择弹框 */
      judgeRelatedData(type) {
        if (type == 0) {
          this.SHOW_DEPARTMENTBOX(true);
        } else if (type == 1) {
          this.SHOW_PERSONNELBOX(true);
        } else if (type == 2) {
          //单据代码(日常收入单:"DOC-C-006" 日常支出单:"DOC-C-007" 销售支出单:"DOC-C-008")
          if (this.billsCode == "DOC-C-006" || this.billsCode == "DOC-C-008") {
            this.SHOW_CUSTOMERBOX(true); //客户弹框
          } else if (this.billsCode = "DOC-C-007") {
            this.SHOW_SUPPLIERBOX(true); //供应商弹框
          }
        } else if (type == 3) {
          this.SHOW_COSTTYPEBOX(true);
        }
      },

      /* 关闭弹框 */
      close() {
        this.$refs['ruleForm'].resetFields(); //清楚表单验证
        // Object.assign(this.$data.form, this.$options.data().form); //初始化form
        this.$emit('close')
      },

      /* 获取页面数据 */
      getListDataByCondition(data) {
        this.$emit('getListDataByCondition', data)
      },

      /* 获取日志信息 */
      getLogContent() {
        let objFiled = {
          billsDate: '单据日期',
          orderBank: this.labelOrderBank,
          orderBankId: this.labelOrderBank + 'id',
          totalAmount: '金额',
          dept: '部门',
          deptId: '部门id',
          userName: '员工',
          userId: '员工id',
          customerName: this.labelCustomer,
          customerId: this.labelCustomer + 'id',
          reason: '事由',
          costType: '费用分类',
          costTypeId: '费用分类id',
        }
        let keys = Object.keys(this.form) //定义匹配key值
        let difKeys = [] //定义不同key值数组
        let logContent = '' //日志内容
        keys.forEach(item => {
          if (this.form[item] != this.sourceData[item]) { //匹配出修改的字段
            logContent += '更改了【' + objFiled[item] + '】,更改前:' + this.form[item] + ",更改后:" + this.sourceData[item] +
              ';' //拼接日志信息
          }
        })
        return logContent
      },

      /* 新增单据 */
      makesure() {
        //表单校验
        let validate = false
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            validate = true
          }
        })
        if (!validate) return
        if (JSON.stringify(this.editForm) != "{}") { //修改时
          let logContent = this.getLogContent() //获取日志信息
          // 定义修改单据对象
          let data = {
            costBillsExpense: JSON.stringify(this.form),
            logContent
          }
          console.log(logContent)
          return
          //发送请求
          costApi.updateBillsExpense(data).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: "数据修改成功",
                duration: this.elDuration
              })
              this.close();
              this.getListDataByCondition(0)
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
          return
        }
        this.form.createUserId = this.UserInfo.user_id //制单人
        // 定义新增单据对象
        let costData = {
          billingName: this.billData.bills_type, //单据类型名称
          costBillsExpense: JSON.stringify(this.form), //	基本信息
          nodeHours: '', //工时信息
          processId: this.form.processId, //流程id
        }
        //发送请求
        costApi.addBillsExpense(costData).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: "数据添加成功",
              duration: this.elDuration
            })
            this.close(); //关闭弹窗
            this.getListDataByCondition(0) //刷新数据
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      }
    },
    components: {
      departmentTree,
      personnelList,
      customerList,
      costTypeTree,
      supplierList
    }
  }
</script>

<style lang="less" scoped>
  .ze_input {
    width: 80% !important;
  }

  .icon {
    padding-right: 5px;
    cursor: pointer;
  }
</style>
